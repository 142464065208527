import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Img404 from '../images/404-ghost.jpg';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>You just hit a page that we can't find.</p>
    <p>Try another url or go back to <a href="/">home</a>.</p>
    <img src={Img404} alt="Missing" data-name="missing" />


  </Layout>
);

export default NotFoundPage;
